import axios from 'axios';
import React, { useState, useEffect } from "react";
import Select from "react-select";
import generateBarcode from '../components/barcodeHelper';
import ProductTable from './products/ProductTable';
import Modal from '../components/Modal';
import ProductDetails from './products/ProductDetails';
import { createWooCommerceProduct, getCategoriesWooCommerce } from '../components/woocommerceHelper';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { calculateFees } from '../components/amountHelper';
const API_URL = process.env?.REACT_APP_PEGASUS_API_URL;


const StoreQuickProduct = () => {

    const [products, setProducts] = useState([]);
    const [stores, setStores] = useState([]); // Store list for dropdown
    const [picture, setPicture] = useState(null);
    const [currentProduct, setCurrentProduct] = useState(null);
    const [productCategories, setProductCategories] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [fees, setFees] = useState(0);
    const [defaultData, setDefaultData] = useState({
        name: '',
        description: '',
        regular_price: '',
        discounted_price: '',
        category: null,
        url_product: '',
        availability: true,
        barcode: generateBarcode()?.barcodeValue,
        details: [],
        type: "borne",
        stores: [] // Updated to handle store-specific availability
    });
    const [sitecategories, setSiteCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        regular_price: '',
        discounted_price: '',
        url_product: '',
        barcode: generateBarcode()?.barcodeValue,
        category: null,
        availability: true,
        details: [],
        type: "borne",
        stores: [] // Updated to handle store-specific availability
    });
    const [editingProduct, setEditingProduct] = useState(null); // New state for editing product
    useEffect(() => {
        fetchProducts();
        fetchStores(); // Fetch stores for dropdown
        getWooCategories();

    }, []);
    useEffect(() => {
        setDefaultData({
            ...defaultData,
            barcode: generateBarcode()?.barcodeValue
        });

        setPicture(null);
       
    }, [currentProduct, editingProduct]);
    const getWooCategories = async () => {
        const categoriesWoo = await getCategoriesWooCommerce();
        setSiteCategories(categoriesWoo);
    }
    const siteHandleModal = (currProd)=>{
       
        setCurrentProduct(currProd);
        const currenProdStore = stores.find(
            x => x.type === "site" && currentProduct.stores?.some(s => String(s.value) === String(x._id))
          );
      
          if(currenProdStore){
            const feesamount = calculateFees(currenProdStore.fees.type,currenProdStore.fees.value,currentProduct.regular_price);
            setFees(feesamount ?? 0);        
          }

    }
    const addinWebsite = async () => {
        try {
          
            toast.info("Veuillez patienter, votre produit est en cours d'ajout.");
            const data = {
                name: currentProduct.name,
                type: 'simple',
                regular_price: currentProduct.regular_price + parseInt(fees),
                description: currentProduct.description,
                categories: selectedCategories.map(cat => ({ id: cat.value })),
                image: process.env.REACT_APP_DATA_PIC_PATH + "/" + currentProduct.url_product,
                barcode: currentProduct?.barcode ?? generateBarcode()?.barcodeValue
            };
            const response = await createWooCommerceProduct(data);
           
            if (response.status == 500) {
                toast.error("Erreur lors l'ajout du produit au Site");
            } else {
                toast.success("Produit ajouté avec succès au site " + currentProduct.name);
                if (response.status == 201 && response.data) {
                    await axios.post(`${API_URL}/products/${currentProduct._id}/woocommerce`, { idwoo: response.data.id }, {
                        headers: {
                            'ENCRYKEY': process.env?.REACT_APP_KEY_PEGASUS,
                        },
                    }).then(response_ => {
                        if (response_.data.success) {
                            setProducts(prevProducts =>
                                prevProducts.map(product =>
                                    product._id === currentProduct._id
                                        ? { ...product, woocommerce_id: response.data.id } // Update the attribute
                                        : product
                                )
                            );

                            toast.success(response_.data.message);
                        } else {
                            toast.error(response_.data.message);
                        }
                    });
                }
            }

        } catch (ex) {

        } finally {
            setCurrentProduct(null);
            setSelectedCategories([]);
            setFees(0);
        }

    }

    const fetchProducts = async () => {
        const response = await axios.get(`${API_URL}/products`, {
            headers: {
                'ENCRYKEY': 'd721a498b6f1ff55f1eb4dfb197825eaa3b7e75491o747a719c30b9d6310ac8c',
            },
        });
        setProducts(response.data);
        const response_ = await axios.get(`${API_URL}/productsCategories`, {
            headers: {
                'ENCRYKEY': 'd721a498b6f1ff55f1eb4dfb197825eaa3b7e75491o747a719c30b9d6310ac8c',
            },
        });
        setProductCategories(response_.data);

    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

   
    const handleEditClick = (product) => {
        console.log(product);
        setEditingProduct(product); // Set the product to edit
        setFormData({
            name: product.name,
            description: product.description,
            regular_price: product.regular_price,
            discounted_price: product.discounted_price,
            category: product.category || null,
            stores: product.stores || [], // Ensure stores are set
            type: product.type ?? "borne",
            barcode: product.barcode ?? generateBarcode()?.barcodeValue
        });
        window.scrollTo({ top: 100, behavior: "smooth" });
    };
    const handleStoreIdsChange = (selectedOptions) => {
        setFormData({
            ...formData,
            stores: selectedOptions.map(option => ({
                store_id: option.value,
                availability: true // Default availability to true for each store
            }))
        });
    };
    const handleproductCategoryChange = (selectedOptions) => {
        console.log(selectedOptions);
        setFormData({
            ...formData,
            category: selectedOptions.value
        });
    };


    const handleStoreAvailabilityChange = (storeId, availability) => {
        setFormData({
            ...formData,
            stores: formData.stores.map(store =>
                store.store_id === storeId ? { ...store, availability } : store
            )
        });
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            // Check if required fields are filled
            if (!formData.name || !formData.regular_price || !formData.description) {
                alert("Please fill out all required fields.");
                return;
            }

            // Determine if we're adding or updating
            const isAdding = !editingProduct;

            // If adding and a picture is provided, upload the picture first
            if ( picture) {
                const formData_ = new FormData();
                formData_.append('productName', formData.name);
                formData_.append('price', formData.regular_price);
                formData_.append('description', formData.description);
                formData_.append('picture', picture);

                // Upload the picture
                const uploadResponse = await axios.post(
                    'https://office.le-celadon.ma/uploadimagesgetUrl.php',
                    formData_,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );

                if (!uploadResponse.data.success) {
                    alert('Error uploading picture: ' + uploadResponse.data.error);
                    return;
                }

                // Add the picture URL to the form data
                formData.url_product = uploadResponse.data.filename;
            }

          
            // Determine the API endpoint and method
            const method = isAdding ? 'post' : 'put';
            const apiEndpoint = isAdding
                ? `${API_URL}/products`
                : `${API_URL}/products/${editingProduct._id}`;

            // Send the request to add or update the product
            const response = await axios[method](apiEndpoint, formData, {
                headers: {
                    ENCRYKEY: 'd721a498b6f1ff55f1eb4dfb197825eaa3b7e75491o747a719c30b9d6310ac8c', // Add any required headers
                },
            });

            // Handle the response
            if (response.data.success) {
                setDefaultData({
                    ...defaultData,
                    barcode: generateBarcode()?.barcodeValue
                })
                setFormData(defaultData);
                alert(
                    `Product ${isAdding ? 'added' : 'updated'}: ${response.data.product.name}`
                );
                setEditingProduct(null); // Reset editing state
                setPicture(null);
                document.getElementById("picture").value=null;
                fetchProducts(); // Refresh the product list
            } else {
                alert('Error: ' + response.data.error);
            }
        } catch (error) {
            console.error('Error:', error);
            alert('An error occurred. Please try again.');
        }
    };
    const updateAvailability = async (productId, storeId, availability) => {
        try {
            await axios.post(
                `${API_URL}/products/${productId}/store/${storeId}/availability`,
                { availability },
                { headers: { "Content-Type": "application/json", 'ENCRYKEY': 'd721a498b6f1ff55f1eb4dfb197825eaa3b7e75491o747a719c30b9d6310ac8c' } }
            ).then(r => {
                console.log("Disponibilité mise à jour :", r.data);
                fetchProducts();
            });
        } catch (error) {
            console.error("Erreur lors de la mise à jour :", error.response?.data || error.message);
        }
    };

    const updateGlobalAvailability = async (productId, availability) => {
        try {
            const response = await axios.post(
                `${API_URL}/products/${productId}/availability`,
                { availability },
                { headers: { "Content-Type": "application/json", 'ENCRYKEY': 'd721a498b6f1ff55f1eb4dfb197825eaa3b7e75491o747a719c30b9d6310ac8c' } }
            );
            fetchProducts();
            console.log("Disponibilité mise à jour :", response.data);
            return response.data;
        } catch (error) {
            console.error("Erreur lors de la mise à jour :", error.response?.data || error.message);
            return { success: false, message: error.response?.data?.message || "Erreur inconnue" };
        }
    };

    const handleDelete = async (id) => {
        await axios.delete(`${API_URL}/products/${id}`, {
            headers: {
                'ENCRYKEY': 'd721a498b6f1ff55f1eb4dfb197825eaa3b7e75491o747a719c30b9d6310ac8c',
            },
        }).then(response => {
            alert(response.data.message);
        });
        fetchProducts();
    };

    const handlePictureChange = (e) => {
        setPicture(e.target.files[0]);
    };

    const fetchStores = async () => {
        const header = {
            headers: {
                'ENCRYKEY': 'd721a498b6f1ff55f1eb4dfb197825eaa3b7e75491o747a719c30b9d6310ac8c',
            },
        };
        const response = await axios.get(`${API_URL}/distinctStoreIds`, header);
        setStores(response.data.response.map(store => ({
            value: store.storeCode,
            label: store.name,
            type:store.store_type,
            fees:store.fees
        })));
    };

    return (
        <div className="container-fluid mt-4">
            <div className='text-end'><button className='btn' onClick={fetchProducts}><i class="fa-solid fa-rotate-right"></i> Reload</button></div>
            <ToastContainer />
            <h2>Product Management <button className='btn btn-primary' data-bs-toggle="modal"
                data-bs-target="#crudModal" onClick={() => {
                    setEditingProduct(null); setDefaultData({
                        ...defaultData,
                        barcode: generateBarcode()?.barcodeValue
                    }); setFormData(defaultData);
                }}>Ajouter un Produit</button></h2>


            {(products && products.length && <ProductTable
                products={products ?? []}
                productCategories={productCategories}
                stores={stores}
                updateGlobalAvailability={updateGlobalAvailability}
                updateAvailability={updateAvailability}
                handleEditClick={handleEditClick}
                handleDelete={handleDelete}
                setCurrentProduct={setCurrentProduct}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                siteHandleModal={siteHandleModal}
            />)}


            <Modal modalId="exampleModal" title="Details du produit">
                {(currentProduct && stores && <ProductDetails product={currentProduct ?? defaultData} productCategories={productCategories} stores={stores} />)}
            </Modal>
            <Modal modalId="categoriesSite" title="Catégorie du Site" submitLabel="Ajouter au Site" onSubmit={addinWebsite}>
                <div className="mb-3">
                    <label htmlFor="categories" className="form-label">Catégories</label>
                    <Select
                        id="categories"
                        isMulti
                        options={sitecategories}
                        value={selectedCategories}
                        onChange={setSelectedCategories}

                    />
                </div>
                {currentProduct && (<>
                    <div className="mb-3">
                        <label className="form-label">Commission ou Frais Supp.</label>
                        <input type="number" className="form-control" name="increment" min={0}  value={fees} onChange={(e)=>setFees(e.target.value)} />
                </div>
                <div className='text-end'>
                    
                    <h3>{currentProduct.regular_price} + {fees ?? 0}</h3>
                    <h2 >Prix Sur Site : <span class="badge bg-warning badge-warning"> {currentProduct.regular_price+(fees ?? 0 )*1} DHS</span></h2>
                   
                </div>
                </>)}
              
            </Modal>
            <Modal title={editingProduct ? 'Modify Product' : 'Add Product'} modalId="crudModal" submitLabel={editingProduct ? 'Update Product' : 'Add Product'} onSubmit={handleSubmit}>

                <form className="mb-4">
                    <div className="mb-3">
                        <label className="form-label">Name</label>
                        <input type="text" className="form-control" name="name" value={formData.name} onChange={handleChange} required />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Description</label>
                        <input type="text" className="form-control" name="description" value={formData.description} onChange={handleChange} />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Code à Bar</label>
                        <input type="text" className="form-control" name="barcode" readOnly={true} value={formData.barcode} onChange={handleChange} />
                    </div>
                    <div className='row'>


                        <div className='col-6'> <div className="mb-3">
                            <label className="form-label">Prix Régulier</label>
                            <input type="number" className="form-control" name="regular_price" value={formData.regular_price} onChange={handleChange} required />
                        </div></div>
                        <div className='col-6'><div className="mb-3">
                            <label className="form-label">Prix Remisé</label>
                            <input type="number" className="form-control" name="discounted_price" value={formData.discounted_price} onChange={handleChange} />
                        </div></div>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="picture" className="form-label">Picture</label>
                        <input type="file" className="form-control" id="picture"  onChange={handlePictureChange} />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Catégorie</label>
                        <Select
                            placeholder="Selectionner la catégorie du produit"
                            options={productCategories.map(m => ({ value: m._id, label: m.name }))}
                            value={productCategories.map(m => ({ value: m._id, label: m.name })).find(x => x.value == formData.category) || null}
                            onChange={handleproductCategoryChange}

                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Store IDs</label>
                        <Select
                            isMulti
                            options={stores}
                            value={formData.stores.map(store => ({ value: store.store_id, label: (stores.find(s => s.value == store.store_id)?.label) }))}
                            onChange={handleStoreIdsChange}
                            className="basic-multi-select"
                            classNamePrefix="select"
                        />
                    </div>
                 
                    

                </form>
            </Modal>
        </div>
    );
};

export default StoreQuickProduct;
