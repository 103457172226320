  import axios from "axios";
  export const getCategoriesWooCommerce = async () => {
    try {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_API_CLIENT_WEBSITE}:${process.env.REACT_APP_API_SECRET_WEBSITE}`)
        };

        const response = await axios.get(
            `${process.env.REACT_APP_WEBSITE_API}/wp-json/wc/v3/products/categories?per_page=100`,
            { headers }
        );

        const decodeText = (v)=>{
            return  new DOMParser().parseFromString(v, "text/html").body.textContent;
        }
        // Ensure response contains data
        if (response.data) {
           
            return response.data.map(x => ({ value: x.id, label:decodeText(x.name)}));
        } else {
            console.warn("Empty response from WooCommerce API");
            return [];
        }
    } catch (error) {
        console.error("Error fetching WooCommerce categories:", error);
        return []; // Return empty array in case of an error
    }
};

  export const createWooCommerceProduct = async (objectWoo) => {
    try{
        const data = {
            name: objectWoo.name,
            type: 'simple',
            regular_price: String(objectWoo.regular_price)  ,
            description: objectWoo.description,
            categories: objectWoo.categories,
            images: [{ src: objectWoo.image }],
            sku:"CLDMA"+objectWoo.barcode,
                    
           
          };
      
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_API_CLIENT_WEBSITE}:${process.env.REACT_APP_API_SECRET_WEBSITE}`)
          };
      
         const response = await axios.post(`${process.env.REACT_APP_WEBSITE_API}/wp-json/wc/v3/products`, data, { headers });
       
         if(response.status==201){
            const resupdate = await axios.put(`${process.env.REACT_APP_WEBSITE_API}/wp-json/wc/v3/products/${response.data.id}`,{
            meta_data: [
                  {
                    key: "rank_math_focus_keyword",
                    value: objectWoo.name
                  },
                  {
                    key: "ean",
                    value: objectWoo.barcode
                  },
                  {
                    key: "gtin",
                    value: objectWoo.barcode
                  },
                  {
                    key: "_alg_ean",
                    value: objectWoo.barcode
                  },
                  
                ]  ,
                ean:objectWoo.barcode
              },{ headers });

         }
         return response;
    }catch(ex){
        return {status:500};
    }
   
    
  };

