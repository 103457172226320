import React, { useState, useEffect, useRef } from "react";
import JsBarcode from "jsbarcode";
import Modal from "../../components/Modal";

const ProductTable = ({ products, productCategories, stores,siteHandleModal, setCurrentProduct, updateGlobalAvailability, updateAvailability, handleEditClick, handleDelete ,currentPage,setCurrentPage }) => {
  const [searchTerm, setSearchTerm] = useState("");
 
  const itemsPerPage = 10;
  const barcodeRefs = useRef({});
  const maxPagesToShow = 5;
 
  useEffect(() => {
    // Iterate over products and generate barcode for each one
    products.forEach((product) => {
      const barcodeElement = barcodeRefs.current[product._id];
      if (barcodeElement && product.barcode) {
        JsBarcode(barcodeElement, product.barcode, {
          format: "EAN13",
          lineColor: "#0b0b0b",
          width: 1,
          height: 50,
          displayValue: true,
        });
      }
    });
  }, [products,currentPage]);
    const filteredProducts = products?.filter(product =>
      product.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  

  // Pagination logic
  const totalPages = Math.ceil((filteredProducts?.length ?? 1) / itemsPerPage);
  const displayedProducts = filteredProducts?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
  let endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

  if (endPage - startPage < maxPagesToShow - 1) {
    startPage = Math.max(1, endPage - maxPagesToShow + 1);
  }
  return (
    <div>
      {/* Search Input */}
      <input
        type="text"
        placeholder="Search by name..."
        className="form-control mb-3"
        value={searchTerm}
        onChange={(e) => {
          setSearchTerm(e.target.value);
          setCurrentPage(1); // Reset to first page when searching
        }}
      />

      {/* Table */}
      <table className="table table-bordered table-responsive table-striped w-100">
        <thead>
          <tr>
            <th className="col-2">Bar code</th>
            <th>Image</th>
            <th>Name</th>
           
            <th>Disponibilité</th>
           
            <th>Store Availability</th>
            <th>Stock</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {displayedProducts.map((product) => (
            <tr key={product._id}>
              <td  width="100px">
                
              <svg
                  ref={(el) => {
                    if (el) {
                      barcodeRefs.current[product._id] = el; // Assign ref to each barcode SVG
                    }
                  }}
                  width={100}
                />
               
              </td>
              <td>
                <img src={process.env.REACT_APP_DATA_PIC_PATH + `/${product.url_product}`} width={100} />
              </td>
              <td>{product.name}</td>
             
              <td>
                <input
                  type="checkbox"
                  checked={product.availability}
                  onChange={() => updateGlobalAvailability(product._id, !product.availability)}
                />
              </td>
              
              <td>
                {product.stores.map((store, index) => (
                  <div key={index} className={`badge text-bg-${store.availability ? "success" : "danger"} mx-1`}>
                    <span>{stores.find(s => s.value === store.store_id)?.label || "Inconnu"}</span>
                    <input
                      type="checkbox"
                      checked={store.availability}
                      onChange={(e) => updateAvailability(product._id, store.store_id, e.target.checked)}
                    />
                  </div>
                ))}
              </td>
              <td className="w-auto">
                {product.stores.map((store, index) => (
                  <div key={index} className="bg-info m-1 p-2">
                    <span>
                      <span className="badge text-bg-warning">{store.stock ?? 0}</span> {stores.find(s => s.value === store.store_id)?.label || "Inconnu"}
                    </span>
                    <button className="btn btn-primary m-2"><i className="fa-solid fa-pen-to-square"></i></button>
                  </div>
                ))}
              </td>
              <td>
                <button className="btn btn-warning m-1" data-bs-toggle="modal"
                data-bs-target="#crudModal" onClick={() => handleEditClick(product)}>Modify</button>
                <button className="btn btn-danger m-1" onClick={() => handleDelete(product._id)}>Delete</button>
                <button className="btn btn-primary m-1" onClick={() => setCurrentProduct(product)}  data-bs-toggle="modal"
                data-bs-target="#exampleModal"> Détails</button>
               {(!product?.woocommerce_id || product?.woocommerce_id ==null || product?.woocommerce_id =="") && <button className="btn btn-primary m-1" onClick={() => siteHandleModal(product)}  data-bs-toggle="modal"
                data-bs-target="#categoriesSite"> <i class="fa-solid fa-earth-africa"></i> Site</button>} 
              </td>
            </tr>
          ))}
        </tbody>
      </table>

 
      {/* Pagination Controls */}
      <nav className="mt-4">
      <ul className="pagination justify-content-center">
        {/* First Page */}
        <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
          <button className="page-link" onClick={() => setCurrentPage(1)}>
            First
          </button>
        </li>

        {/* Previous Page */}
        <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
          <button className="page-link" onClick={() => setCurrentPage(currentPage - 1)}>
            Previous
          </button>
        </li>

        {/* Page Numbers */}
        {Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i).map((page) => (
          <li key={page} className={`page-item ${page === currentPage ? "active" : ""}`}>
            <button className="page-link" onClick={() => setCurrentPage(page)}>
              {page}
            </button>
          </li>
        ))}

        {/* Next Page */}
        <li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
          <button className="page-link" onClick={() => setCurrentPage(currentPage + 1)}>
            Next
          </button>
        </li>

        {/* Last Page */}
        <li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
          <button className="page-link" onClick={() => setCurrentPage(totalPages)}>
            Last
          </button>
        </li>
      </ul>
    </nav>

    </div>
  );
};

export default ProductTable;
