import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Modal from './Modal';
import axios from 'axios';

import Select from "react-select";


function Sidebar(props) {
    const API_URL = process.env?.REACT_APP_PEGASUS_API_URL;
    const [notallowed, setNotAllowed] = useState(process.env.REACT_APP_NOT_ALLOWED);
    const [stores, setStores] = useState([]);
    const [selectedStore, setSelectedStore] = useState([]);
    const isAllowed = () => {
        return !notallowed.split(',').includes(sessionStorage.getItem("name"));
    }
    const fetchStores = async () => {
        const header = {
            headers: {
                'ENCRYKEY': process.env?.REACT_APP_KEY_PEGASUS,
            },
        };
        const response = await axios.get(`${API_URL}/distinctStoreIds`, header);
        setStores(response.data.response.filter(x=>x.store_type=="glovo" || x.store_type=="site").map(store => ({
            value: store.storeCode,
            label: store.name
        })));
    };
    const handleStoreIdsChange = (selected) => {
        window.open('/storeExpress/'+selected.value,'_blank');
    };

    return (
        <nav id="sidebar" className="sidebar js-sidebar">
            <div className="sidebar-content js-simplebar">
                <a className="sidebar-brand" href="index.html">
                    <span className="align-middle">Le Céladon Manager</span>
                </a>

                <ul className="sidebar-nav">


                    {isAllowed() && <>


                        <li className="sidebar-header">
                            Pages
                        </li>

                        <li className="sidebar-item active">
                            <Link className="sidebar-link" to={"/"}>
                                <i className="align-middle" data-feather="sliders"></i> <span className="align-middle">Dashboard</span>
                            </Link>
                        </li>
                        <li className="sidebar-item">
                            <Link className="sidebar-link" to="/cmiExcel">
                                <i class="fa-solid fa-file-excel"></i> <span className="align-middle">Cmi Excel</span>
                            </Link>
                        </li>

                        <li className="sidebar-item">
                            <a className="sidebar-link" href="/tracker">
                                <i class="fa-solid fa-map-location-dot"></i>  <span className="align-middle">Track Vehicules</span>
                            </a>
                        </li>

                        <li className="sidebar-item">
                            <a className="sidebar-link" href="/stockHouse">
                                <i class="fa-solid fa-warehouse"></i> <span className="align-middle">Stock By Store</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a className="sidebar-link" href="/storeQuickProduct">
                                <i class="fa-solid fa-display"></i> <span className="align-middle">Produit sur la borne</span>
                            </a>
                        </li>
                    </>}




                    <li className="sidebar-item">
                        <a className="sidebar-link" href="/stockhandling">
                            <i className="align-middle" data-feather="book"></i> <span className="align-middle">Gestion des ventes</span>
                        </a>
                    </li>



                    {isAllowed() && <>
                        <li className="sidebar-header">
                            Products
                        </li>
                        <li className="sidebar-item">
                            <a className="sidebar-link" href="/addproductsite">
                                <i class="fa-solid fa-globe"></i> <span className="align-middle">Ajouter Produit WebSite</span>
                            </a>
                        </li>

                        <li className="sidebar-item">
                            <a className="sidebar-link" href="/productList">
                                <i className="align-middle" data-feather="check-square"></i> <span className="align-middle">Voir les produits</span>
                            </a>
                        </li>

                        <li className="sidebar-item">
                            <a className="sidebar-link" href="/ordersGlovo">
                                <i class="fa-solid fa-motorcycle"></i> <span className="align-middle">Cmd. Glovo</span>
                            </a>
                        </li>

                        <li className="sidebar-item">
                            <a className="sidebar-link" href="/carrefourecommerce">
                                <i class="fa-solid fa-shop"></i> <span className="align-middle">Carrefour Ecommerce</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a className="sidebar-link" href="/barcode">
                                <i class="fa-solid fa-barcode"></i> <span className="align-middle">Code à Bar</span>
                            </a>
                        </li>

                        <li className="sidebar-item">
                            <a className="sidebar-link" href="#" onClick={fetchStores} data-bs-toggle="modal"
                data-bs-target="#storesSideBar">
                                <i className="align-middle" data-feather="book"></i> <span className="align-middle">Store express</span>
                            </a>
                        </li>


                    </>}


                </ul>


            </div>
            <Modal modalId="storesSideBar" title="Choix du Store">
                <div className="col-12">
                    <label className="form-label">Store IDs</label>
                    <Select
                       
                        options={stores}
                        value={selectedStore}
                        onChange={handleStoreIdsChange}
                        className="basic-multi-select"
                        classNamePrefix="select"
                    />
                </div>
            </Modal>
        </nav>
    );
}

export default Sidebar;