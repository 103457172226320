export const calculateFees = (type, value, regularPrice) => {
    // Vérifier si l'un des paramètres est manquant ou invalide
    if (!type || value === undefined || regularPrice === undefined) {
      return 0;  // Retourne 0 si l'un des paramètres est absent
    }
  
    let feeAmount = 0;
  
    if (type === "amount") {
      feeAmount = value;
    } else if (type === "percent") {
      feeAmount = (regularPrice * (value / 100));
    }
  
    return feeAmount;
  }
  