import React, { useRef,useEffect } from "react";
import JsBarcode from "jsbarcode";
const ProductDetails = ({ product, productCategories, stores }) => {
      // Barcode ref for each product
  
  return (
    <div className="card p-3">
      <h5 className="card-title">Product Details</h5>
      <div className="mb-2">
        <strong>Barcode:</strong>
        {product?.barcode}
      </div>
      <div className="mb-2">
        <strong>Image:</strong>
        <img src={process.env.REACT_APP_DATA_PIC_PATH + `/${product.url_product}`} width={100} alt={product.name} />
      </div>
      <div className="mb-2"><strong>Name:</strong> {product.name}</div>
      <div className="mb-2"><strong>Regular Price:</strong> {product.regular_price}</div>
      <div className="mb-2"><strong>WooCommerce Id:</strong> <a href={`https://www.le-celadon.ma/wp-admin/post.php?post=${product?.woocommerce_id}&action=edit`} target="_blank">{product?.woocommerce_id}</a> </div>
      <div className="mb-2"><strong>Discounted Price:</strong> {product.discounted_price}</div>
      <div className="mb-2"><strong>Availability:</strong> {product.availability ? "Available" : "Unavailable"}</div>
      <div className="mb-2"><strong>Category:</strong> {productCategories.find(x => x._id === product.category)?.name}</div>
      <div className="mb-2">
        <strong>Stores:</strong>
        {product.stores.map((store, index) => (
          <div key={index} className={`badge text-bg-${store.availability ? "success" : "danger"} mx-1`}>
            <span>{stores.find(s => s.value === store.store_id)?.label || "Inconnu"}</span>
          </div>
        ))}
      </div>
      <div className="mb-2">
        <strong>Stock:</strong>
        {product?.stores?.map((store, index) => (
          <div key={index} className="bg-info m-1 p-2">
            <span>
              <span className="badge text-bg-warning">{store.stock ?? 0}</span> {stores.find(s => s.value === store.store_id)?.label || "Inconnu"}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductDetails;
