import React, { useState } from "react";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";



function StoreFormData({ formData, setFormData , setStep }) {
  const [inputName, setInputName] = useState("");
  const [showKeyboard, setShowKeyboard] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone" && !/^[0-9]*$/.test(value)) return; // Restrict phone to numeric
    setFormData({ ...formData, [name]: value });
  };
  const layouts = {
    phone: {
      default: ["1 2 3", "4 5 6", "7 8 9", "+ 0 -", "{bksp}"],
    },
    keyboard: {
      default: [
        "a z e r t y u i o p",
        "q s d f g h j k l m",
        "w x c v b n , ; : !",
        "{shift} {space} {bksp}"
      ],
      shift: [
        "A Z E R T Y U I O P",
        "Q S D F G H J K L M",
        "W X C V B N ? . / %",
        "{shift} {space} {bksp}"
      ]
    }
  };


  const onChange = (input) => {
    setFormData({ ...formData, [inputName]: input });
  };

  const handleFocus = (name) => {
    setInputName(name);
    setShowKeyboard(true);
  };

  return (
    <div className="row">
      <div className="col-4 text-center">
       
      </div>
      <div className="col-4">
        <div className="p-3">
          <div className="mb-3">
            <label className="form-label montserrat-italic h1">Nom et Prénom</label>
            <input
              type="text"
              className="form-control form-control-lg"
              name="name"
              value={formData.name}
              onChange={handleChange}
              onFocus={() => handleFocus("name")}
              autoComplete="off"
            />
          </div>

          <div className="mb-3">
            <label className="form-label montserrat-italic h2">Numéro de téléphone</label>
            <input
              type="text"
              className="form-control form-control-lg"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              onFocus={() => handleFocus("phone")}
              autoComplete="off"

            />
          </div>
        </div>
      </div>
      <div className="col-4 text-center">

      </div>

      {showKeyboard && (
        <>
        <div className="col-2 mt-3"></div>
        <div className="col-8 mt-3">
          <Keyboard
            onChange={onChange}
            inputName={inputName}
            layout={inputName === "phone" ? layouts.phone : layouts.keyboard}
          />
        </div>
        <div className="col-2 mt-3"></div>
        </>
      )}

      <div className="col-12 text-center"> 
        <a className="link h1" onClick={()=>{setFormData({name:"Client Store",phone:"-"});setStep(2);}}>Passer <i class="fa-solid fa-arrow-right"></i></a>
      </div>
    </div>
  );
}

export default StoreFormData;
