import React, { useState } from 'react';
import axios, { all } from 'axios';
import Select from 'react-select';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import env from "react-dotenv";
function CmiAccounting(props) {

    const [key, setKey] = useState(0);
    const [selectedFile, setSelectedFile] = useState(null);
    const [facture, setFacture] = useState(null);
    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };
    const [selectAll, setSelectAll] = useState(false);
    const [selectedOrders, setSelectedOrders] = useState([]);
    const [chosenCompany, setChosenCompany] = useState()

    const handleCheckboxChange = (remiseId) => {
        // Toggle the selection state of the order private note
        setSelectedOrders((prevSelectedOrders) => {
            if (prevSelectedOrders.includes(remiseId)) {
                return prevSelectedOrders.filter((order) => order !== remiseId);
            } else {
                return [...prevSelectedOrders, remiseId];
            }
        });

    };
    const getParams = () => {
        return JSON.parse(process.env?.REACT_APP_CLIENTS)?.find(x => x.name == chosenCompany.value);
    }

    const ProcessedTransactionsCmiView = async (trx) => {
        var allRemiseNumbers = trx.map(m => m.remiseId);

        const resPegasus = await axios.post(
            process.env.REACT_APP_PEGASUS_API_URL + "/getProcessedCmi",
            { remises: allRemiseNumbers, company: chosenCompany.value },
            {
                headers: {
                    ENCRYKEY: 'd721a498b6f1ff55f1eb4dfb197825eaa3b7e75491o747a719c30b9d6310ac8c'
                }
            }
        );

        if (resPegasus.status != 200) {
            alert("Erreur attention à vos actions , de préference appeler l'administrateur");
        } else {
            setFacture(trx.map(m => ({ ...m, processed: ((resPegasus.data.find(r => r.remiseId == m.remiseId)) ? true : false), invoiceId: resPegasus.data.find(r => r.remiseId == m.remiseId)?.invoiceId, invoiceNumber: resPegasus.data.find(r => r.remiseId == m.remiseId)?.invoiceNumber })));
            setSelectedOrders(selectedOrders.filter(x => !resPegasus.data.includes(x.remiseId)));

        }

    }
    const validateAll = async () => {
        if (facture && selectedOrders.length > 0) {
            selectedOrders.map(s => {
                const invoiceid = facture.find(x => x.remiseId == s)?.invoiceId;
                if (invoiceid) {
                    validateInvoice(invoiceid);

                }

            });
            setSelectedOrders([]);
        }


    }

    const handleActionButtonClick = async () => {
        if (facture && chosenCompany && selectedOrders.length && window.confirm("Etes-vous sûr de bien vouloir traiter le fichier !")) {
            var formatedCmiTrx = facture.filter(f => selectedOrders.includes(f.remiseId)).map(m => ({
                socid: m.socid,
                date: m.datef.split('/').reverse().join('-'),
                datef: m.datef.split('/').reverse().join('-'),
                remiseId: m.remiseId,
                mode_reglement_code: "CB",
                mode_reglement_id: "6",
                fk_account: (getParams()?.site == m.socid && chosenCompany?.value == "oflorist") ? "11" : "4",
                invoiceNumber: "",
                company: chosenCompany.label,
                note_private: m.note_private,
                lines: [{
                    multicurrency_code: "MAD",
                    label: m.lines[0].desc,
                    desc: m.lines[0].desc,
                    tva_tx: chosenCompany?.value == "oflorist" ? 0 : 20,
                    qty: 1,
                    subprice: (chosenCompany?.value == "oflorist") ? m.lines[0].subprice : m.lines[0].subprice / 1.2,
                    product_type: "product",
                    trx: m.lines[0].trx
                }]
            }));

            const tmpRepos = await axios.post(process.env.REACT_APP_PEGASUS_API_URL + "/addInvoices", { trx: formatedCmiTrx, company: chosenCompany.value, token: ((chosenCompany.value == "oflorist") ? sessionStorage.getItem("token") : getParams()?.token) }
                ,
                {
                    headers: {
                        ENCRYKEY: 'd721a498b6f1ff55f1eb4dfb197825eaa3b7e75491o747a719c30b9d6310ac8c'
                    }
                }
            );
            if (tmpRepos.status == 200) {
                toast.success("PROV a été bien ajoutée à Accounting");
                ProcessedTransactionsCmiView(facture);

                // Supprimer les éléments traités de selectedOrders
                formatedCmiTrx.forEach(trx => {
                    const index = selectedOrders.indexOf(trx.remiseId);
                    if (index > -1) {
                        selectedOrders.splice(index, 1);
                    }
                });

                setSelectedOrders([...selectedOrders]);
            } else {
                alert("Une erreur est survenue");
            }
        }


    };
    const handleUpload = () => {
        console.log("in")
        if (selectedFile) {
            const formData = new FormData();
            formData.append('excelFile', selectedFile);
            formData.append('company', chosenCompany?.value);

            axios.post(process.env.REACT_APP_API_OFFICE, formData, {
                withCredentials: true,
            })
                .then(async response => {
                    ProcessedTransactionsCmiView(response.data);
                })
                .catch(error => console.error('Error:', error));
        } else {
            console.error('No file selected');
        }


    };
    const validateInvoice = (invoiceId) => {
        const token = sessionStorage.getItem('token');

        axios.post(`${getParams()?.urlErp}api/index.php/invoices/${invoiceId}/validate`, {}, {
            headers: {
                DOLAPIKEY: chosenCompany.value == "oflorist" ? token : getParams()?.token,
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                if (res.status === 200) {
                    toast.success("Valider et réference définie " + res.data.ref);
                    return res.data; // Utilisez res.data au lieu de res.json() avec axios
                } else {
                    return null;
                }
            })
            .then(data => {
                if (data) {
                    if (updateCmiRef(data.id, data.ref)) {

                    }
                }


                else {
                    console.log("Aucune donnée reçue.");
                }
            })
            .catch(error => {
                alert("Une erreur est survenue");
                console.error(error); // Ajout de la journalisation de l'erreur
            });

    }


    const updateCmiRef = (invoiceId, ref) => {
        var result = false;
        axios.post(process.env.REACT_APP_PEGASUS_API_URL + "/invoiceValidation", {
            invoiceId,
            ref
        },
            {
                headers: {
                    ENCRYKEY: 'd721a498b6f1ff55f1eb4dfb197825eaa3b7e75491o747a719c30b9d6310ac8c'
                }
            }).then(res => {
                if (res.status == 200 && res.data.success) {
                    const token = sessionStorage.getItem("token")
                    axios.post(`${getParams()?.urlErp}api/index.php/invoices/${invoiceId}/settopaid`, {}, {
                        headers: {
                            DOLAPIKEY: chosenCompany.value == "oflorist" ? token : getParams()?.token,
                            'Content-Type': 'application/json',
                        }
                    })
                        .then(res => {
                            if (res.status === 200) {
                                console.log(invoiceId);
                                const updatedFacture = facture.map(f => f.invoiceId == invoiceId ? { ...f, invoiceNumber: ref } : f);
                                setFacture(updatedFacture);
                                toast.success("Payment de la facture " + ref);
                                result = true;
                            } else {
                                return null;
                            }
                        }).catch(e => {
                            toast.error("Erreur lors du paiement");
                        })
                    result = true;
                }
            });
        return result;

    }

    const resetComponents = () => {
        setKey(prevKey => prevKey + 1); // Increment key to force remount
        setSelectedFile(null);    // Reset selectedFile to null
        setFacture(null);         // Reset facture to null
        setSelectAll(false);      // Reset selectAll to false
        setSelectedOrders([]);    // Reset selectedOrders to an empty array
        setChosenCompany(undefined); // Reset chosenCompany to undefined
    };


    const handleSelectAllChange = () => {
        setSelectAll(!selectAll);
        if (!selectAll) {
            const allOrderNotes = facture.filter(x => !x.processed).map((f) => f.remiseId);
            setSelectedOrders(allOrderNotes);
        } else {
            setSelectedOrders([]);
        }
    };
    return (
        <div className="container mt-5" key={key}>
            <h1 className="h3 mb-3"><strong>Cmi</strong> Comptabilité</h1>
            {chosenCompany && <button className='btn btn-warning mb-2' onClick={resetComponents}>Nouveau Fichier</button>}
            <div>
                <Select

                    options={JSON.parse(process.env.REACT_APP_CLIENTS)?.map(x => ({ label: x.name, value: x.name }))}
                    value={chosenCompany}
                    onChange={setChosenCompany}
                    isDisabled={!!chosenCompany?.value}
                />
            </div>
            {chosenCompany && (
                <>
                    {!facture && (<>
                        <h2>Upload Excel File</h2>
                        <div className="mb-3">
                            <input type="file" onChange={handleFileChange} />
                        </div>
                        <button className="btn btn-primary" onClick={handleUpload}>
                            Upload
                        </button>
                    </>)}



                    <table className=' mt-2 table table-responsive'>
                        <thead className='bg-warning'>
                            <tr >
                                <th> <input
                                    type="checkbox"
                                    checked={selectAll}
                                    onChange={handleSelectAllChange}
                                />
                                </th>
                                <th>Client</th>
                                <th>Remise Numéro</th>
                                <th>Nombre de transactions</th>
                                <th>Date de facture</th>
                                <th>Nom Produit</th>
                                <th>Montant Total</th>
                                <th>Processed</th>
                                <th>Facture Numéro</th>
                                <th>Type</th>
                                <th>Invoice Id</th>

                            </tr>
                        </thead>

                        {(facture) ? (<><tbody>
                            {facture.sort((a, b) => a.socid - b.socid).map((f, i) => (
                                <tr className={(f.processed) ? ((i % 2 == 0) ? "bg-success" : "bg-success-l") : ""}>
                                    <td><input
                                        type="checkbox"
                                        checked={selectedOrders.includes(f.remiseId)}
                                        disabled={f.processed}
                                        onChange={() => handleCheckboxChange(f.remiseId)}
                                    />
                                    </td>
                                    <td>
                                        <span className={`badge bg-${getParams()?.site == f.socid ? "primary" : "warning"}`}>
                                            {getParams()?.site == f.socid ? "SITE" : "TPE"}
                                        </span>
                                    </td>

                                    <td>{f.note_private}</td>
                                    <td>{f.lines[0].trx}</td>
                                    <td>{f.datef}</td>
                                    <td>{f.lines[0].desc}</td>
                                    <td><span className='badge bg-dark text-light '>{f.lines[0].subprice} <small> {f.lines[0].currency}</small></span></td>
                                    <td>{(f.processed) ? <i class="fa-regular fa-circle-check"></i> : <i class="fa-solid fa-hourglass"></i>}</td>
                                    <td>{f?.invoiceNumber}</td>
                                    <td>{f.lines[0].product_type}</td>
                                    <td>
                                        <a href={`${getParams()?.urlErp}compta/facture/card.php?facid=${f?.invoiceId}`} target='_blank' className='btn btn-info'>
                                            {f?.invoiceId}
                                        </a>
                                        {f.processed ? (
                                            <button className='btn btn-warning' onClick={() => validateInvoice(f?.invoiceId)}>
                                                Valider
                                            </button>
                                        ) : ''}
                                    </td>

                                </tr>
                            ))
                            }
                        </tbody>
                            <tfoot>
                                <tr>
                                    <td className='text-end' colSpan={7}>
                                        <h1>TOTAL : <span className='badge bg-warning'>{facture.reduce((acc, a) => acc + a.lines[0].subprice, 0)} MAD</span> </h1>
                                    </td>
                                </tr>
                            </tfoot>
                        </>
                        ) : ''}



                    </table>
                    <button onClick={handleActionButtonClick}>Ajouter à Accounting</button>
                </>
            )}

            <ToastContainer />
        </div>
    );
}

export default CmiAccounting;